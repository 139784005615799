<template>
  <v-tabs
    v-model="tabSelected"
    background-color="primary"
    center-active
    dark
    @change="cambiarDireccion()"
  >
    <v-tabs-slider color="secondary"></v-tabs-slider>
    <v-tab>Sesiones</v-tab>
    <!-- <v-tab>Invitaciones</v-tab> -->
    <v-tab v-show="userAdmin">Administrar</v-tab>
    <v-tab v-show="userAdmin">Consejos</v-tab>
    <v-tab v-show="userAdmin">Usuarios</v-tab>
    <v-tab v-show="userAdmin">Dependencias</v-tab>
    <v-tab>Constancias</v-tab>
  </v-tabs>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "mainMenuTabsComponent",
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  watch: {
    "$route.path"() {
      this.setTabSelected();
    },
  },
  data() {
    return {
      loading: false,
      tabSelected: 0,
      adminSesion:true
    };
  },
  mounted() {
    this.setTabSelected();
  },
  methods: {
    setTabSelected() {
      const path = this.$route.path;
      if (path == "/main/sesiones") this.tabSelected = 0;
      // if (path == "/main/invitaciones") this.tabSelected = 1;
      if (path == "/main/admin") this.tabSelected = 1;
      if (path == "/main/consejos") this.tabSelected = 2;
      if (path == "/main/usuarios") this.tabSelected = 3;
      if (path == "/main/dependencias") this.tabSelected = 4;
    },
    cambiarDireccion() {
      if (this.tabSelected == 0) this.$router.push("/main/sesiones");
    //  if (this.tabSelected == 1) this.$router.push("/main/invitaciones");
      if (this.tabSelected == 1) this.$router.push("/main/admin");
      if (this.tabSelected == 2) this.$router.push("/main/consejos");
      if (this.tabSelected == 3) this.$router.push("/main/usuarios");
      if (this.tabSelected == 4) this.$router.push("/main/dependencias");
      if (this.tabSelected == 5) this.$router.push("/main/constancias");

    },
  },
};
</script>

